import React from "react";

import SkewRectangleHeroImg from "../../../images/skewRectangleHero.png";

const HeroSection = () => {
  return (
    <section
      className="about-hero"
      style={{ backgroundImage: `url(${SkewRectangleHeroImg})` }}
    >
      <div className="about-hero-content container" data-aos="fade-up">
        <h1 className="heading-primary">
          We support people with our innovative products
        </h1>
        <p className="paragraph-primary">
          Our mutual values render us an ideal partner for clients and contractors 
          who strive to achieve their goals through daily routines. Collaborating together 
          allows us to provide top-notch services and ensure our customers' success.
        </p>
      </div>
    </section>
  );
};

export default HeroSection;
