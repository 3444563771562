import * as React from "react";

import "../styles/styles.scss";

import About from "../components/About/About.jsx";

const IndexPage = () => {
  return <About />;
};

export default IndexPage;
