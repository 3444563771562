import React from "react";

import LeftRectangle from "../../../images/left-rectangle.svg";
import RightRectangle from "../../../images/right-rectangle.svg";

const QuoteSection = () => {
  return (
    <section className="quote-section">
      <div className="quote-section-content container">
        <div className="quote-section-content-wrapper">
          <img src={LeftRectangle} alt="" className="left-rectangle-img" />
          <img src={RightRectangle} alt="" className="right-rectangle-img" />
          <h2
            className="quote-section-content-wrapper-heading"
            data-aos="fade-up"
          >
            We help you every step of the way to get the best results and advise
            you on software solutions to help you achieve your goals. Whatever
            your needs are, we deliver the right software
            for you
          </h2>
        </div>
      </div>
    </section>
  );
};

export default QuoteSection;
