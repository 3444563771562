import React from "react";

import CEOImgFirst from "../../../images/ceo-photo-first.png";
import CEOImgSecond from "../../../images/ceo-photo-second.png";

const TeamSection = () => {
  return (
    <section className="team-section container">
      <h2 className="team-section-heading heading-secondary">
        People behind our company
      </h2>
      <div className="team-section-content">
        <div
          className="team-section-content-item"
          data-aos="fade-up"
          data-aos-delay={50}
        >
          <div className="team-section-content-item-left">
            <img src={CEOImgSecond} alt="" />
          </div>
          <div className="team-section-content-item-right">
            <h3 className="heading-third">Tomasz Garbowski</h3>
            <span>Co-Founder, President</span>
            <p className="paragraph-primary">
              Tomasz has many years of experience in IT, including roles as a
              programmer, integration designer and system architect. His
              expertise includes Java related technologies, web apps and system integration. He has worked 
              on a number of large-scale insurance and financial IT projects.
              Tomasz is a graduate of West Pomeranian University of Technology and Poznan University of Technology.
            </p>
          </div>
        </div>
        <div
          className="team-section-content-item"
          data-aos="fade-up"
          data-aos-delay={150}
        >
          <div className="team-section-content-item-left">
            <img src={CEOImgFirst} alt="" />
          </div>
          <div className="team-section-content-item-right">
            <h3 className="heading-third">Kamil Majewski</h3>
            <span>Co-Founder, Vice-President</span>
            <p className="paragraph-primary">
              Kamil worked for over 15 years for Poland's top IT company. There, he progressed from
              a programmer to an architect and led a programming team for many years. He has 
              expertise in Java related technologies and database apps. Kamil has worked on big IT projects for
              insurance and financial companies. Kamil is a graduate of West Pomeranian University of Technology.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamSection;
