import React, { useEffect } from "react";
import { Seo } from "../SEO/Seo.jsx";
import CTABanner from "../CTABanner/CTABanner";

import MainMenu from "../MainMenu/MainMenu";
import Footer from "../Footer/Footer.jsx";
import HeroSection from "./components/HeroSection.jsx";
import QuoteSection from "./components/QuoteSection.jsx";
import StorySection from "./components/StorySection.jsx";
import TeamSection from "./components/TeamSection.jsx";
import ClientsSection from "./components/ClientsSection";

import AOS from "aos";
import "aos/dist/aos.css";

const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: true,
    });
  }, []);
  return (
    <main>
      <Seo
        title="About us | Gammasoft: Custom Software Development Company"
        description="Find out who we are, what our values are and what we can do"
      />
      <MainMenu />
      <HeroSection />
      <QuoteSection />
      <StorySection />
      <TeamSection />
      <ClientsSection />
      <CTABanner />
      <Footer />
    </main>
  );
};

export default About;
