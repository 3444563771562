import React from "react";

const StorySection = () => {
  return (
    <section className="story-section" id="story-section">
      <div className="story-section-content container">
        <div className="story-section-content-left">
          <h2 className="heading-secondary" data-aos="fade-right">
            The history of GammaSoft
          </h2>
          <p className="paragraph-primary" data-aos="fade-down">
            GammaSoft Sp. z o.o. was established in 2011 by a team of highly
            skilled computer science professionals with over 20 years of
            experience. As subcontractors, we have been involved in some of the
            largest IT projects in Poland, primarily in the insurance and
            finance sectors.
          </p>
        </div>
        <div className="story-section-content-right">
          <p className="paragraph-primary" data-aos="fade-down">
            For the past decade, we have been focused on creating technology
            that enhances people's lives. This includes designing and developing
            IT systems, web and mobile applications, and specialized software
            and electronics for the Internet Of Things. We also have a strong track
            record in integrating systems and creating e-commerce solutions.
          </p>
        </div>
      </div>
      <div className="story-btn" data-aos="fade-down">
        <a className="btn btn-secondary" href="/services">
          <span>
            Explore our offerings
            <svg
              version="1.1"
              id="Layer_1"
              xmlns="http://www.w3.org/2000/svg"
              x="0px"
              y="0px"
              viewBox="0 0 36.1 25.8"
            >
              <g>
                <line
                  fill="none"
                  stroke="white"
                  strokeWidth="3"
                  x1="0"
                  y1="12.9"
                  x2="34"
                  y2="12.9"
                ></line>
                <polyline
                  fill="none"
                  stroke="white"
                  strokeWidth="3"
                  points="22.2,1.1 34,12.9 22.2,24.7   "
                ></polyline>
              </g>
            </svg>
          </span>
        </a>
      </div>
    </section>
  );
};

export default StorySection;
